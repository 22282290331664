import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { Block, getStructure } from './structureLib';
import './structure.scss';

function FullBlock(props: { sentences: string[] }) {
  const { sentences } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  if (!expanded) {
    return (
      <div
        onClick={() => {
          setExpanded(true);
        }}
        className="block-container"
      >
        <div className="bordered bordered-top"></div>
        <div className="bordered bordered-bottom"></div>
        <div>{sentences[0]}</div>
        <div>...</div>
        <div>{sentences[sentences.length - 1]}</div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          setExpanded(false);
        }}
        className="block-container"
      >
        <div className="bordered bordered-top"></div>
        <div className="bordered bordered-bottom"></div>
        <div>
          {sentences.map((el, index) => (
            <span key={`s_${index}`}>{el} </span>
          ))}
        </div>
      </div>
    );
  }
}

function BriefBlock(props: { sentences: string[] }) {
  const { sentences } = props;
  return (
    <div className="brief-block-container">
      {sentences.map((el, index) => (
        <span key={`s_${index}`}>{el} </span>
      ))}
    </div>
  );
}

interface Props {
  content: string;
  onFadeEnd: () => void;
}

export default forwardRef((props: Props, ref: Ref<void>) => {
  const { content, onFadeEnd } = props;
  const [structure, setStructure] = useState<Block[]>();
  const [fadeOut, setFadeOut] = useState<string>('');

  useImperativeHandle(ref, () => ({
    goFade() {
      setFadeOut('fadable-out');
    }
  }));

  useEffect(() => {
    setStructure(getStructure(content));
  }, [content]);

  return (
    <div
      onAnimationEnd={() => {
        if (fadeOut) {
          onFadeEnd();
          setFadeOut('');
        }
      }}
      className={`editor-content struct-container fadable-in ${fadeOut}`}
    >
      {structure &&
        structure.map((el, index) =>
          el.sentences.length < 3 ? (
            <BriefBlock key={index} sentences={el.sentences} />
          ) : (
            <FullBlock key={index} sentences={el.sentences} />
          )
        )}
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </div>
  );
});
