import React, { useEffect, useState } from 'react';
import './scss/sidebar.scss';
import { ReadingEase } from './nlp-models/reading-ease.enum';
import { Mode } from './nlp-models/mode.enum';
import { Analytics } from './nlp-models/analytics.model';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useGlobalState } from './components/shared/GlobalContext';
import { createTheme, MuiThemeProvider, ThemeProvider, Tooltip } from '@material-ui/core';
import { lightTooltip } from './components/shared/customTooltip';

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ', ');
}

const theme = createTheme({ ...lightTooltip });

export const initStatistic: Analytics = {
  paragraphs: { difficult: [] },
  sentences: { difficult: [], passive: [] },
  words: { difficult: [], adverb: [], verb: [], subject: [], vague: [] },
  usageAnd: { count: 0, percentage: 0, word: 'and' },
  flesch: ReadingEase.DEFAULT,
  stats: {
    paragraphs: 0,
    sentences: 0,
    words: 0,
    avgWordsPerSentence: 0,
    characters: 0,
    readingTime: '0s'
  }
};

const getThemeForPrimaryColor = (primaryColor: string) => ({
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: '#9a9ea8'
      },
      colorSecondary: {
        '&$checked': {
          color: primaryColor
        }
      },
      track: {
        opacity: 0.3,
        backgroundColor: '#9a9ea8',
        '$checked$checked + &': {
          opacity: 0.3,
          backgroundColor: primaryColor
        }
      }
    }
  }
});

const themeForVerbSwitcher = createTheme(getThemeForPrimaryColor('#0a66d2'));
const themeForSubjectSwitcher = createTheme(getThemeForPrimaryColor('#ffaf00'));

export function Sidebar(props: {
  switchMode: (mode: Mode) => void;
  activeMode: Mode;
  onShowStructureSwitch: (show: boolean) => void;
  readOnly?: boolean;
  statistic: Analytics;
  templateGuide?: string;
}) {
  const { statistic, switchMode, activeMode, onShowStructureSwitch, readOnly, templateGuide } = props;
  const { showVerbs, setShowVerbs, showSubjects, setShowSubjects } = useGlobalState();
  const [statsShowMore, setStatsShowMore] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const showMore = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setStatsShowMore(!statsShowMore);
  };

  useEffect(() => {
    if (showVerbs) {
      setShowSubjects(false);
    }
  }, [showVerbs]);

  useEffect(() => {
    if (showSubjects) {
      setShowVerbs(false);
    }
  }, [showSubjects]);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="sidebar">
        <div className="tab-container">
          {!readOnly && (
            <div
              className={'tab-item' + (activeMode === Mode.WRITE ? ' active' : '')}
              onClick={() => {
                switchMode(Mode.WRITE);
                setChecked(false);
                onShowStructureSwitch(false);
              }}
            >
              <span className="icon write" />
              <span className="title">
                <span>W</span>rite
              </span>
            </div>
          )}
          <div
            className={'tab-item' + (activeMode === Mode.ANALYZE ? ' active' : '')}
            onClick={() => switchMode(Mode.ANALYZE)}
          >
            <span className="icon analyze" />
            <span className="title">
              <span>A</span>nalyze
            </span>
          </div>
          <div
            className={'tab-item' + (activeMode === Mode.STATS ? ' active' : '')}
            onClick={() => {
              switchMode(Mode.STATS);
              setChecked(false);
              onShowStructureSwitch(false);
            }}
          >
            <span className="icon stats" />
            <span className="title">
              <span>S</span>tats
            </span>
          </div>
        </div>
        {activeMode === Mode.ANALYZE ? (
          <div className={`sections ${activeMode !== Mode.ANALYZE ? 'hide' : ''}`}>
            <div className="section">
              <h4>Reading Difficulty</h4>
              <div className="moderate">
                <h4>{statistic.flesch.replace('-', ' ')}</h4>
                <div id="states">
                  <span
                    className={`${ReadingEase.VERY_EASY} ${statistic.flesch === ReadingEase.VERY_EASY ? 'active' : ''}`}
                  />
                  <span className={`${ReadingEase.EASY} ${statistic.flesch === ReadingEase.EASY ? 'active' : ''}`} />
                  <span
                    className={`${ReadingEase.MODERATE} ${statistic.flesch === ReadingEase.MODERATE ? 'active' : ''}`}
                  />
                  <span className={`${ReadingEase.HARD} ${statistic.flesch === ReadingEase.HARD ? 'active' : ''}`} />
                  <span
                    className={`${ReadingEase.VERY_HARD} ${statistic.flesch === ReadingEase.VERY_HARD ? 'active' : ''}`}
                  />
                </div>
                <div className="states-description">
                  <span>Easy</span>
                  <span>Hard</span>
                </div>
              </div>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="switcher-holder">
                  <Tooltip
                    className="custom-tooltip"
                    title="This feature connects the first sentence of every paragraph to the last sentence. This unique view helps you spot gaps in the logical flow of your writing."
                    placement="bottom"
                    arrow
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      style={{ margin: 0, padding: 0, marginRight: 40 }}
                      control={
                        <Switch
                          color="secondary"
                          checked={checked}
                          onChange={(event: any) => {
                            switchMode(Mode.ANALYZE);
                            setChecked(event.target.checked);
                            onShowStructureSwitch(event.target.checked);
                          }}
                        />
                      }
                      label="Show structure"
                    />
                  </Tooltip>
                </div>
                <div className="switcher-holder">
                  <ThemeProvider theme={themeForVerbSwitcher}>
                    <FormControlLabel
                      labelPlacement="start"
                      style={{ margin: 0, padding: 0 }}
                      control={
                        <Switch
                          checked={showVerbs}
                          onChange={() => {
                            setShowVerbs(!showVerbs);
                          }}
                        />
                      }
                      label="Show only verbs"
                    />
                  </ThemeProvider>
                </div>
                <div className="switcher-holder">
                  <ThemeProvider theme={themeForSubjectSwitcher}>
                    <FormControlLabel
                      labelPlacement="start"
                      style={{ margin: 0, padding: 0 }}
                      control={
                        <Switch
                          color="secondary"
                          checked={showSubjects}
                          onChange={() => {
                            setShowSubjects(!showSubjects);
                          }}
                        />
                      }
                      label="Show only subjects"
                    />
                  </ThemeProvider>
                </div>
              </div>

              <div id="stats" className={statsShowMore ? 'show-more' : ''}>
                <p id="words">
                  Words: <span>{numberWithCommas(statistic.stats.words)}</span>
                </p>
                <div>
                  <p id="characters">
                    Characters: <span>{numberWithCommas(statistic.stats.characters)}</span>
                  </p>
                  <p id="sentences">
                    Sentences: <span>{numberWithCommas(statistic.stats.sentences)}</span>
                  </p>
                  <p id="paragraphs">
                    Paragraphs: <span>{numberWithCommas(statistic.stats.paragraphs)}</span>
                  </p>
                  <p id="reading-time">
                    Reading Time: <span>{statistic.stats.readingTime}</span>
                  </p>
                  <p id="average-words-per-sentence">
                    Avg. words per sentence: <span>{statistic.stats.avgWordsPerSentence}</span>
                  </p>
                </div>

                <button className="action more" onClick={showMore}>
                  {statsShowMore ? 'Show less' : 'Show more stats'}
                </button>
              </div>
            </div>

            <div className="section">
              <h4>Paragraphs</h4>

              <div className="rules">
                <div id="difficult-paragraphs">
                  <span>{statistic.paragraphs.difficult.length}</span>Difficult paragraphs
                  <Tooltip
                    className="custom-tooltip"
                    title="Keep your paragraphs to 7 sentences or less. Long paragraphs can be hard to understand."
                    placement="bottom"
                    arrow
                  >
                    <div className="info"></div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="section">
              <h4>Sentences</h4>
              <div className="rules">
                <div id="difficult-sentences">
                  <span>{statistic.sentences.difficult.length}</span>Difficult sentences
                  <Tooltip
                    className="custom-tooltip"
                    title="Keep sentences to 25 words or less. Long sentences can confuse readers."
                    placement="bottom"
                    arrow
                  >
                    <div className="info"></div>
                  </Tooltip>
                </div>
                <div id="passive-voice">
                  <span>{statistic.sentences.passive.length}</span>Passive voice
                  <Tooltip
                    className="custom-tooltip"
                    title="Use active voice whenever you can. “The goalkeeper made a mistake,” is clearer than, “Mistakes were made.”"
                    placement="bottom"
                    arrow
                  >
                    <div className="info"></div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="section">
              <h4>Words</h4>
              <div className="rules">
                <div id="difficult-words">
                  <span>{statistic.words.difficult.length}</span>Difficult words
                  <Tooltip
                    className="custom-tooltip"
                    title="Words with more than 4 or 5 syllables can be difficult to read. Try to use simple words with fewer syllables for clearer writing. "
                    placement="bottom"
                    arrow
                  >
                    <div className="info"></div>
                  </Tooltip>
                </div>
                <div id="vague-words">
                  <span>{statistic.words.vague.length}</span>Vague words
                </div>
                <div id="adverb">
                  <span>{statistic.words.adverb.length}</span>Adverbs
                  <Tooltip
                    className="custom-tooltip"
                    title="Using fewer adverbs can lighten up your writing and provide more context. Example: “He thumped the file on the desk, then slammed the door on his way out,” is livelier and has more details than “He left angrily.”"
                    placement="bottom"
                    arrow
                  >
                    <div className="info"></div>
                  </Tooltip>
                </div>
              </div>
            </div>

            {statistic.usageAnd.percentage >= 4 && (
              <div className="section">
                <h4>Repetitive</h4>
                <div className="rules">
                  <Tooltip
                    title={`The frequency of the word "and" exceeds 4% of all words. Excessive use of the word "and" can link unrelated ideas. If possible, please try to reduce its usage.`}
                    placement="bottom-end"
                  >
                    <div id="duplicates">
                      <span>{statistic.usageAnd.count}</span>Usage of “and”
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {activeMode === Mode.WRITE && templateGuide ? (
          <div className="template-guide" dangerouslySetInnerHTML={{ __html: templateGuide }} />
        ) : (
          ''
        )}
      </div>
    </MuiThemeProvider>
  );
}

export default Sidebar;
