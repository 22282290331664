import React, { useEffect } from 'react';
import './topMenu.scss';
import { useHistory } from 'react-router-dom';
// import { Button, withStyles } from '@material-ui/core';
// import Menu, { MenuProps } from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// const StyledMenu = withStyles({
//   paper: {
//     paddingTop: 10,
//     paddingBottom: 10,
//     border: '1px solid #D4D7DD',
//     boxSizing: 'border-box',
//     boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
//     borderRadius: 8
//   }
// })((props: MenuProps) => (
//   <Menu
//     elevation={0}
//     getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right'
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right'
//     }}
//     {...props}
//   />
// ));

// const StyledMenuItem = withStyles(theme => ({
//   root: {
//     paddingLeft: 29,
//     paddingRight: 67,
//     paddingTop: 7,
//     paddingBottom: 7,
//     '&:focus': {
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: theme.palette.common.white
//       }
//     }
//   }
// }))(MenuItem);

export default function TopMenu(props: { showDocuments: boolean; showUserMenu?: boolean }) {
  // const history = useHistory();
  // const { showDocuments } = props;

  // useEffect(() => {
  //   document.title = 'Homer';
  // }, [history]);

  return (
    <div className="top-menu">
      <div className="menu-left-container">
        <div className="title">
          <a href="/">Homer</a>
        </div>
        {/* {showDocuments && (
          <a href="/">
            <div className="documents">Documents</div>
          </a>
        )} */}
      </div>
    </div>
  );
}
