interface ModificationMap {
  sPosOpen: number;
  ePosOpen: number;
  sPosClose?: number;
  ePosClose?: number;
}

type ConditionProcessor = (s: string) => boolean;

function regexIndexOf(str: string, regex: RegExp | string, startPos = 0): number {
  const indexOf = str.substring(startPos || 0).search(regex);
  return indexOf >= 0 ? indexOf + (startPos || 0) : indexOf;
}

// function regexLastIndexOf(str: string, regex: RegExp, startPos = 0): number {
//   if (typeof startPos == 'undefined') {
//     startPos = str.length;
//   } else if (startPos < 0) {
//     startPos = 0;
//   }
//   const stringToWorkWith = str.substring(0, startPos + 1);
//   let lastIndexOf = -1;
//   let nextStop = 0;
//   let result;
//   while ((result = regex.exec(stringToWorkWith)) != null) {
//     lastIndexOf = result.index;
//     regex.lastIndex = ++nextStop;
//   }
//   return lastIndexOf;
// }

const tagsToRemove = [
  { name: 'table', single: false },
  { name: 'tr', single: false },
  { name: 'td', single: false },
  { name: 'img', single: true },
  { name: 'font', single: false },
  { name: 'meta', single: true },
  { name: 'span', single: false }
];

function attributeStartsWith(str: string, attrName: string, prefix: string): boolean {
  return regexIndexOf(str, `\\s${attrName}\\=["']${prefix}`) >= 0;
}

function attributeIncludes(str, attrName, search) {
  return regexIndexOf(str, `\\s${attrName}\\=["'].*?${search}`) >= 0;
}

function getModificationMap(
  pos: number,
  str: string,
  tagName: string,
  isSingle: boolean,
  condition?: ConditionProcessor,
  nonGreed = false
): ModificationMap | undefined {
  let modificationMap: ModificationMap;

  const r = new RegExp(`<${tagName}[\\s>]`, 'i');
  const sPosOpen = regexIndexOf(str, r, pos);

  if (sPosOpen < 0) {
    return undefined;
  }

  const ePosOpen = str.indexOf('>', sPosOpen);

  if (condition && !condition(str.substring(sPosOpen, ePosOpen))) {
    return undefined;
  }

  if (isSingle) {
    modificationMap = {
      sPosOpen,
      ePosOpen
    };
  } else {
    const sPosClose = nonGreed ? str.indexOf(`</${tagName}>`, sPosOpen) : str.lastIndexOf(`</${tagName}>`, sPosOpen);
    modificationMap = {
      sPosOpen,
      ePosOpen,
      sPosClose,
      ePosClose: sPosClose + `</${tagName}>`.length - 1
    };
  }
  return modificationMap;
}

function cropTags(str: string, tagName: string, isSingle: boolean, condition?: ConditionProcessor): string {
  let startPos = 0;
  while (startPos < str.length) {
    const modificationMap = getModificationMap(
      startPos === 0 ? 0 : startPos - 1,
      str,
      tagName,
      isSingle,
      condition,
      true
    );
    if (modificationMap) {
      if (!isSingle && modificationMap.ePosClose !== undefined) {
        str = str.slice(0, modificationMap.sPosClose) + str.slice(modificationMap.ePosClose + 1);
      }
      str = str.slice(0, modificationMap.sPosOpen) + str.slice(modificationMap.ePosOpen + 1);
    }
    startPos++;
  }
  return str;
}

function replaceTags(
  str: string,
  tagName: string,
  isSingle: boolean,
  newTagName: string,
  condition?: ConditionProcessor
): string {
  let startPos = 0;
  while (startPos < str.length) {
    const modificationMap = getModificationMap(
      startPos === 0 ? 0 : startPos - 1,
      str,
      tagName,
      isSingle,
      condition,
      true
    );
    if (modificationMap) {
      if (!isSingle && modificationMap.ePosClose !== undefined) {
        str = str.slice(0, modificationMap.sPosClose) + `</${newTagName}>` + str.slice(modificationMap.ePosClose + 1);
      }
      str = str.slice(0, modificationMap.sPosOpen) + `<${newTagName}>` + str.slice(modificationMap.ePosOpen + 1);
    }
    startPos++;
  }
  return str;
}

function cropAttributes(str: string, attrName: string): string {
  const r = new RegExp(`\\s+${attrName}=["](.*?)["']`, 'gi');
  return str.replace(r, '');
}

// mostly from PDF
function transformPureText(str: string): string {
  const pos = regexIndexOf(str, new RegExp(`<p`, 'i'));
  if (pos < 0) {
    str = `<p>${str}</p>`;
    str = str.replace(new RegExp('\\n\\n', 'g'), '</p><p>');
  }
  return str;
}

export function normalizeHTML(source: string): string {
  source = cropTags(source, 'b', false, openTagContent =>
    attributeStartsWith(openTagContent, 'id', 'docs\\-internal\\-guid')
  );
  source = replaceTags(source, 'span', false, 'b', openTagContent =>
    attributeIncludes(openTagContent, 'style', 'font\\-weight\\:700')
  );
  source = cropTags(source, 'br', true, openTagContent =>
    attributeStartsWith(openTagContent, 'class', 'Apple-interchange-newline')
  );
  for (const t of tagsToRemove) {
    source = cropTags(source, t.name, t.single);
  }
  source = cropAttributes(source, 'class');
  source = cropAttributes(source, 'style');
  source = cropAttributes(source, 'dir');
  return transformPureText(source);
}
