import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

export const materialTheme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: '#0967D2'
      // dark: '#2F80ED'
    },
    secondary: {
      main: '#12cdf8'
      // dark: '#2F80ED'
    }
  },
  typography: {
    fontFamily: 'Inter',
    button: {
      fontFamily: 'Inter',
      textTransform: 'none',
      height: 44,
      borderRadius: 4
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 16,
        fontFamily: 'Inter',
        paddingRight: 22,
        paddingLeft: 22,
        paddingBottom: 22
      }
    },
    MuiDialogTitle: {
      root: {
        fontFamily: 'Inter'
      }
    },
    MuiDialogContent: {
      root: {
        fontFamily: 'Inter'
      }
    },
    MuiDialogActions: {
      root: {
        fontFamily: 'Inter',
        paddingRight: 20
      }
    },
    MuiDialogContentText: {
      root: {
        fontFamily: 'Inter'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#495864',
        '&:hover': {
          color: '#202430'
        }
      }
    }
  }
});
