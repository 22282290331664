/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React from 'react';
import { Router, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { history } from '../../shared/helpers';
import './app.scss';
import Dashboard from '../dashboard/Dashboard';
import { GlobalProvider } from '../shared/GlobalContext';

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Router history={history}>
          <Switch>
            <GlobalProvider>
              <Route path="/" exact component={Dashboard} />
            </GlobalProvider>
            <Redirect to="/" />
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
}
