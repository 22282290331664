export const lightTooltip = {
  overrides: {
    MuiTooltip: {
      arrow: {
        '&::before': {
          color: '#3B4146',
          backgroundColor: '#fff'
        }
      },
      tooltip: {
        color: '#3B4146',
        backgroundColor: '#fff',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 12,
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 13,
        paddingBottom: 19,
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px'
      }
    }
  }
};
