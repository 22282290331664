import React, { useEffect } from 'react';
import { Axis, Interval, Tooltip } from 'bizcharts';
import { Analytics } from '../../nlp-models/analytics.model';
import './stats.scss';
import { Chart, Point } from 'bizcharts';
import { initStatistic } from '../../Sidebar';
import { RichValue } from '../../nlp-models/stats.model';
import { createTheme, MuiThemeProvider, Tooltip as UITooltip } from '@material-ui/core';
import { lightTooltip } from '../shared/customTooltip';

const theme = createTheme({ ...lightTooltip });

function BarChart(props: {
  analytics: Analytics;
  xField: string;
  yField: string;
  statField: string;
  tipTitleDesc: string;
  tipValueSingleDesc: string;
  tipValuePluralDesc: string;
}) {
  const { analytics, xField, yField, statField, tipTitleDesc, tipValueSingleDesc, tipValuePluralDesc } = props;
  if (!analytics.stats.chart || !analytics.stats.chart[statField]) {
    return <></>;
  }
  const data = analytics.stats.chart[statField].map((el: RichValue, index: number) => ({
    [xField]: `${index + 1}`,
    [yField]: el.value,
    text: el.text
  }));
  const tickInterval = Math.floor(analytics.stats.chart[statField].length / 10);
  const scale: { tickInterval: number } = { tickInterval, [yField]: { nice: true } };
  if (tickInterval > 0) {
    scale[xField] = { tickInterval };
  }

  return (
    <Chart scale={scale} height={300} autoFit data={data} columnStyle={{ fill: '#56CCF2' }}>
      <Interval position={`${xField}*${yField}`} color={'#56CCF2'} />
      <Axis name={xField} label={{ autoHide: true, autoEllipsis: true }} title />
      <Axis name={yField} title />
      <Tooltip>
        {(_, items: any) => {
          const tipValueDesc = items[0].data[yField] > 1 ? tipValuePluralDesc : tipValueSingleDesc;
          return (
            <div className="stat-chart-tooltip">
              <div className="stat-chart-tooltip-title">
                {tipTitleDesc} {items[0].data[xField]}
              </div>
              <div className="stat-chart-tooltip-value">
                {items[0].data[yField]} {tipValueDesc}
              </div>
              <div className="stat-chart-tooltip-text">{items[0].data.text}...</div>
            </div>
          );
        }}
      </Tooltip>
    </Chart>
  );
}

function PointChart(props: { analytics: Analytics }) {
  const { analytics } = props;
  if (!analytics.stats.chart) {
    return <></>;
  }
  const data: { Syllables: number; Words: number }[] = [];
  for (const syllables of Object.keys(analytics.stats.chart?.wordsWithSyllables)) {
    data.push({
      Syllables: +syllables,
      Words: analytics.stats.chart?.wordsWithSyllables[syllables]
    });
  }
  return (
    <Chart scale={{ Words: { nice: true } }} padding={[50, 50]} height={300} autoFit data={data}>
      <Point position="Syllables*Words" size={7} opacity={1} shape="circle" color={'#56CCF2'} />
      <Axis name="Syllables" title />
      <Axis name="Words" title />
      <Tooltip>
        {(_, items: any) => {
          return (
            <div className="stat-chart-tooltip">
              <div className="stat-chart-tooltip-value">
                There are {items[0].data.Words} {items[0].data.Words > 1 ? 'words' : 'word'} with{' '}
                {items[0].data.Syllables}-{items[0].data.Syllables > 1 ? 'syllables' : 'syllable'}
              </div>
            </div>
          );
        }}
      </Tooltip>
    </Chart>
  );
}

export default function Stats(props: { analytics: Analytics; onContentAnalyze: Function }) {
  const { analytics, onContentAnalyze } = props;

  useEffect(() => {
    if (analytics === initStatistic) {
      onContentAnalyze();
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="stat-container">
        <div className="chart-block">
          <div className="chart-title">
            <div>Sentences per paragraph</div>
            <UITooltip
              className="custom-tooltip"
              title="Keep your paragraphs to 7 sentences or less. Long paragraphs can be hard to understand. This graph can help you identify paragraphs that need shortening."
              placement="bottom"
              arrow
            >
              <div className="info"></div>
            </UITooltip>
          </div>
          <BarChart
            analytics={analytics}
            xField="Paragraphs"
            yField="Sentences"
            statField="paragraphsToSentenceCount"
            tipTitleDesc="Paragraph"
            tipValueSingleDesc="Sentence"
            tipValuePluralDesc="Sentences"
          />
        </div>
        <div className="chart-block">
          <div className="chart-title">
            <div>Words per sentence</div>
            <UITooltip
              className="custom-tooltip"
              title="Sentences of the same length can make your text dry. To create a pleasing rhythm, vary your sentence length, but keep sentences to 25 words or less. This graph identifies sections that need variety."
              placement="bottom"
              arrow
            >
              <div className="info"></div>
            </UITooltip>
          </div>
          <BarChart
            analytics={analytics}
            xField="Sentences"
            yField="Words"
            statField="sentenceToWord"
            tipTitleDesc="Sentence"
            tipValueSingleDesc="Word"
            tipValuePluralDesc="Words"
          />
        </div>
        <div className="chart-block">
          <div className="chart-title">
            <div>Syllables per word</div>
            <UITooltip
              className="custom-tooltip"
              title="Using difficult words with many syllables can result in hard-to-understand text. This graph helps you see whether you're using more simple or difficult words."
              placement="bottom"
              arrow
            >
              <div className="info"></div>
            </UITooltip>
          </div>
          {analytics.stats.chart?.wordsWithSyllables && <PointChart analytics={analytics} />}
        </div>
      </div>
    </MuiThemeProvider>
  );
}
