import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export interface GlobalContextDesc {
  activeContent: string;
  setActiveContent: Function;
  commonWords: Set<string> | null;
  setCommonWords: Function;
  showVerbs: boolean;
  setShowVerbs: (value: boolean) => void;
  showSubjects: boolean;
  setShowSubjects: (value: boolean) => void;
}

const _commonWords = [
  'information',
  'business',
  'people',
  'policy',
  'available',
  'copyright',
  'software',
  'privacy',
  'company',
  'general',
  'university',
  'january',
  'management',
  'united',
  'international',
  'development',
  'education',
  'internet',
  'community',
  'reserved',
  'family',
  'special',
  'technology',
  'related',
  'security',
  'american',
  'following',
  'download',
  'resources',
  'media',
  'history',
  'personal',
  'including',
  'guide',
  'directory',
  'location',
  'digital',
  'department',
  'description',
  'insurance',
  'another',
  'property',
  'quality',
  'december',
  'include',
  'college',
  'article',
  'different',
  'canada',
  'categories',
  'advanced',
  'conditions',
  'category',
  'gallery',
  'register',
  'library',
  'industry',
  'provided',
  'required',
  'accessories',
  'september',
  'medical',
  'application',
  'articles',
  'complete',
  'financial',
  'equipment',
  'memory',
  'performance',
  'social',
  'language',
  'experience',
  'america',
  'important',
  'activities',
  'example',
  'additional',
  'building',
  'february',
  'analysis',
  'possible',
  'professional',
  'committee',
  'washington',
  'interest',
  'california',
  'similar',
  'reference',
  'companies',
  'delivery',
  'popular',
  'computers',
  'president',
  'original',
  'includes',
  'australia',
  'discussion',
  'entertainment',
  'agreement',
  'society',
  'edition',
  'marketing',
  'updated',
  'association',
  'already',
  'specific',
  'several',
  'collection',
  'limited',
  'solutions',
  'natural',
  'electronics',
  'database',
  'official',
  'technical',
  'microsoft',
  'conference',
  'environment',
  'statement',
  'downloads',
  'resource',
  'applications',
  'document',
  'material',
  'federal',
  'requirements',
  'finance',
  'individual',
  'usually',
  'together',
  'economic',
  'subscribe',
  'germany',
  'included',
  'everything',
  'production',
  'commercial',
  'advertising',
  'received',
  'newsletter',
  'knowledge',
  'magazine',
  'currently',
  'construction',
  'registered',
  'protection',
  'policies',
  'india',
  'position',
  'engineering',
  'florida',
  'annual',
  'published',
  'corporate',
  'customers',
  'response',
  'hardware',
  'materials',
  'holiday',
  'designed',
  'remember',
  'political',
  'increase',
  'advertise',
  'environmental',
  'availability',
  'africa',
  'summary',
  'activity',
  'employment',
  'commission',
  'administration',
  'institute',
  'exchange',
  'sponsored',
  'electronic',
  'continue',
  'benefits',
  'someone',
  'anything',
  'condition',
  'effective',
  'organization',
  'selection',
  'casino',
  'corporation',
  'solution',
  'addition',
  'executive',
  'necessary',
  'jewelry',
  'according',
  'particular',
  'military',
  'decision',
  'facilities',
  'opportunities',
  'division',
  'lesbian',
  'appropriate',
  'actually',
  'statistics',
  'capital',
  'investment',
  'saturday',
  'george',
  'registration',
  'furniture',
  'wednesday',
  'virginia',
  'located',
  'multiple',
  'asian',
  'distribution',
  'editor',
  'industrial',
  'potential',
  'featured',
  'responsible',
  'communications',
  'associated',
  'primary',
  'foundation',
  'documents',
  'communication',
  'independent',
  'approach',
  'physical',
  'operating',
  'medicine',
  'chicago',
  'developed',
  'unique',
  'telephone',
  'animal',
  'mexico',
  'population',
  'regular',
  'navigation',
  'operations',
  'evidence',
  'christian',
  'favorite',
  'understand',
  'recently',
  'probably',
  'publications',
  'worldwide',
  'improve',
  'connection',
  'publisher',
  'nokia',
  'introduction',
  'carolina',
  'properties',
  'hospital',
  'accommodation',
  'excellent',
  'italy',
  'opportunity',
  'william',
  'distance',
  'assessment',
  'involved',
  'especially',
  'interface',
  'guides',
  'maximum',
  'operation',
  'existing',
  'quite',
  'selected',
  'amazon',
  'restaurants',
  'beautiful',
  'locations',
  'significant',
  'technologies',
  'animals',
  'manufacturer',
  'providing',
  'catalog',
  'authority',
  'considered',
  'programme',
  'strategy',
  'canadian',
  'enterprise',
  'educational',
  'positive',
  'opinion',
  'asia',
  'develop',
  'alternative',
  'processing',
  'responsibility',
  'resolution',
  'publication',
  'relations',
  'photography',
  'republic',
  'components',
  'vacation',
  'century',
  'academic',
  'assistance',
  'completed',
  'indian',
  'expected',
  'pacific',
  'organizations',
  'vehicle',
  'proposed',
  'require',
  'otherwise',
  'reported',
  'transportation',
  'politics',
  'disclaimer',
  'faculty',
  'membership',
  'modified',
  'released',
  'internal',
  'recommended',
  'detailed',
  'japanese',
  'approved',
  'character',
  'maintenance',
  'ability',
  'trademarks',
  'phentermine',
  'battery',
  'submitted',
  'television',
  'interested',
  'defined',
  'virtual',
  'established',
  'programming',
  'external',
  'regarding',
  'instructions',
  'offered',
  'theory',
  'minimum',
  'visual',
  'variety',
  'manual',
  'increased',
  'understanding',
  'beginning',
  'associates',
  'finally',
  'capacity',
  'requires',
  'instruments',
  'businesses',
  'specified',
  'restaurant',
  'procedures',
  'relationship',
  'georgia',
  'traditional',
  'benefit',
  'families',
  'themselves',
  'interesting',
  'evaluation',
  'accepted',
  'implementation',
  'galleries',
  'references',
  'presented',
  'literature',
  'respective',
  'michigan',
  'columbia',
  'economy',
  'critical',
  'musical',
  'definition',
  'secretary',
  'angeles',
  'networking',
  'australian',
  'magazines',
  'francisco',
  'individuals',
  'colorado',
  'russian',
  'african',
  'relevant',
  'guidelines',
  'installation',
  'described',
  'suite',
  'square',
  'attention',
  'advance',
  'difference',
  'allowed',
  'illinois',
  'regulations',
  'facility',
  'certificate',
  'minister',
  'visitors',
  'documentation',
  'monitor',
  'automotive',
  'successful',
  'communities',
  'clinical',
  'situation',
  'publishing',
  'emergency',
  'developing',
  'currency',
  'determine',
  'temperature',
  'announcements',
  'actual',
  'historical',
  'commerce',
  'ringtones',
  'difficult',
  'scientific',
  'amateur',
  'particularly',
  'cultural',
  'easily',
  'functional',
  'holidays',
  'balance',
  'monitoring',
  'graduate',
  'architecture',
  'initial',
  'recommend',
  'dictionary',
  'accounting',
  'manufacturing',
  'fantasy',
  'professor',
  'generally',
  'continued',
  'techniques',
  'quickly',
  'religion',
  'permission',
  'surgery',
  'generation',
  'chemical',
  'component',
  'enable',
  'guarantee',
  'diamond',
  'processes',
  'arizona',
  'interests',
  'produced',
  'italian',
  'paperback',
  'classifieds',
  'supported',
  'argument',
  'competition',
  'providers',
  'characters',
  'attorney',
  'upgrade',
  'apartments',
  'therapy',
  'generated',
  'administrative',
  'informed',
  'quantity',
  'reporting',
  'essential',
  'affiliate',
  'immediately',
  'defense',
  'designated',
  'recovery',
  'integrated',
  'configuration',
  'comprehensive',
  'universal',
  'presentation',
  'languages',
  'orange',
  'compliance',
  'vehicles',
  'improvement',
  'guitar',
  'pennsylvania',
  'examples',
  'challenge',
  'motorola',
  'acceptance',
  'strategies',
  'intended',
  'affiliates',
  'multimedia',
  'certified',
  'computing',
  'interactive',
  'separate',
  'quarter',
  'procedure',
  'religious',
  'developer',
  'identify',
  'approximately',
  'recommendations',
  'comparison',
  'extended',
  'sequence',
  'reserve',
  'ontario',
  'automatically',
  'minnesota',
  'williams',
  'approval',
  'equal',
  'adventure',
  'offering',
  'institutions',
  'assistant',
  'variable',
  'advertisement',
  'yesterday',
  'compared',
  'determined',
  'russia',
  'extension',
  'statements',
  'completely',
  'portable',
  'electrical',
  'applicable',
  'returned',
  'manufacturers',
  'classical',
  'warranty',
  'dedicated',
  'indiana',
  'basketball',
  'delete',
  'assembly',
  'criminal',
  'sexual',
  'wisconsin',
  'obtained',
  'personnel',
  'identified',
  'opinions',
  'professionals',
  'decided',
  'advantage',
  'priority',
  'newsletters',
  'estimated',
  'anonymous',
  'miscellaneous',
  'prepared',
  'integration',
  'atlanta',
  'dakota',
  'interview',
  'framework',
  'installed',
  'criteria',
  'massachusetts',
  'associate',
  'behavior',
  'enlarge',
  'frequently',
  'revenue',
  'discussions',
  'festival',
  'laboratory',
  'exactly',
  'explore',
  'maryland',
  'eligible',
  'reality',
  'origin',
  'destination',
  'intelligence',
  'followed',
  'specifications',
  'tripadvisor',
  'alaska',
  'residential',
  'decisions',
  'industries',
  'protocol',
  'partnership',
  'editorial',
  'expression',
  'equity',
  'provisions',
  'principles',
  'suggestions',
  'replacement',
  'strategic',
  'economics',
  'compatible',
  'apartment',
  'netherlands',
  'consulting',
  'recreation',
  'designer',
  'managed',
  'marriage',
  'participants',
  'negative',
  'favorites',
  'toronto',
  'missouri',
  'translation',
  'estimates',
  'injury',
  'ministry',
  'protected',
  'proposal',
  'philadelphia',
  'officials',
  'contained',
  'lingerie',
  'legislation',
  'parameters',
  'miami',
  'relationships',
  'tennessee',
  'combined',
  'ultimate',
  'representative',
  'frequency',
  'introduced',
  'departments',
  'residents',
  'reduced',
  'performed',
  'extreme',
  'reviewed',
  'removed',
  'administrator',
  'accuracy',
  'pharmacy',
  'addresses',
  'oklahoma',
  'academy',
  'dynamic',
  'permanent',
  'agriculture',
  'constitutes',
  'portfolio',
  'practical',
  'delivered',
  'collectibles',
  'infrastructure',
  'exclusive',
  'originally',
  'utilities',
  'philosophy',
  'regulation',
  'officers',
  'reduction',
  'referred',
  'nutrition',
  'recording',
  'secondary',
  'wonderful',
  'announced',
  'prevention',
  'presence',
  'automatic',
  'healthcare',
  'maintained',
  'increasing',
  'majority',
  'connected',
  'austria',
  'participation',
  'utility',
  'containing',
  'combination',
  'guaranteed',
  'libraries',
  'distributed',
  'singapore',
  'enterprises',
  'convention',
  'principal',
  'ordering',
  'cruise',
  'certification',
  'previously',
  'buildings',
  'specials',
  'batteries',
  'alabama',
  'improved',
  'positions',
  'subscription',
  'contemporary',
  'exposure',
  'panasonic',
  'permalink',
  'signature',
  'provision',
  'luxury',
  'certainly',
  'newspaper',
  'circuit',
  'removal',
  'liability',
  'trademark',
  'kentucky',
  'factory',
  'interior',
  'americans',
  'optical',
  'promotion',
  'relative',
  'amazing',
  'identity',
  'suites',
  'conversion',
  'reasonable',
  'victoria',
  'serial',
  'revision',
  'broadband',
  'influence',
  'importance',
  'webmaster',
  'prescription',
  'instance',
  'orlando',
  'specifically',
  'represent',
  'conservation',
  'recorded',
  'finished',
  'louisiana',
  'javascript',
  'advisor',
  'marketplace',
  'evolution',
  'certificates',
  'objectives',
  'suggested',
  'concerned',
  'operator',
  'generic',
  'encyclopedia',
  'continuing',
  'interracial',
  'competitive',
  'preparation',
  'receiving',
  'accordance',
  'discussed',
  'accurate',
  'elizabeth',
  'reservations',
  'playstation',
  'alcohol',
  'instruction',
  'managing',
  'annotation',
  'differences',
  'establish',
  'happened',
  'expressed',
  'lesbians',
  'paragraph',
  'mathematics',
  'compensation',
  'managers',
  'conducted',
  'percentage',
  'mississippi',
  'describe',
  'requested',
  'citizens',
  'connecticut',
  'heritage',
  'personals',
  'immediate',
  'agricultural',
  'supporting',
  'audience',
  'assigned',
  'collections',
  'participate',
  'specialist',
  'experienced',
  'investigation',
  'institution',
  'affected',
  'totally',
  'expenses',
  'indicate',
  'proceedings',
  'favourite',
  'transmission',
  'anderson',
  'characteristics',
  'organic',
  'experiences',
  'extremely',
  'verzeichnis',
  'concerning',
  'developers',
  'equivalent',
  'chemistry',
  'neighborhood',
  'nevada',
  'variables',
  'anyway',
  'continues',
  'advisory',
  'curriculum',
  'anywhere',
  'psychology',
  'responses',
  'atlantic',
  'circumstances',
  'investor',
  'identification',
  'wildlife',
  'appliances',
  'elementary',
  'unlimited',
  'entered',
  'printable',
  'guidance',
  'enforcement',
  'hardcover',
  'observed',
  'glossary',
  'celebrity',
  'ericsson',
  'appendix',
  'notify',
  'chocolate',
  'hampshire',
  'controlled',
  'requirement',
  'authorities',
  'biology',
  'representatives',
  'pregnancy',
  'arkansas',
  'biography',
  'attractions',
  'transactions',
  'explorer',
  'historic',
  'attached',
  'disabled',
  'authorized',
  'upcoming',
  'retirement',
  'financing',
  'efficiency',
  'adopted',
  'efficient',
  'commitment',
  'specialty',
  'edited',
  'interviews',
  'qualified',
  'discovery',
  'classified',
  'relating',
  'confidence',
  'alliance',
  'offline',
  'consistent',
  'clearance',
  'connections',
  'inventory',
  'converter',
  'organisation',
  'becoming',
  'safari',
  'objective',
  'indicated',
  'securities',
  'relation',
  'enabled',
  'montana',
  'volunteer',
  'democratic',
  'enhance',
  'switzerland',
  'parameter',
  'adapter',
  'processor',
  'contribute',
  'colleges',
  'challenges',
  'editors',
  'supreme',
  'recognition',
  'submission',
  'estimate',
  'encourage',
  'regulatory',
  'inspection',
  'consumers',
  'territory',
  'transaction',
  'manchester',
  'contributions',
  'continuous',
  'resulting',
  'cambridge',
  'initiative',
  'execution',
  'disability',
  'increases',
  'idaho',
  'contractor',
  'episode',
  'examination',
  'bulletin',
  'indicates',
  'modify',
  'epinions',
  'committed',
  'extensive',
  'affordable',
  'universe',
  'candidate',
  'databases',
  'outstanding',
  'perspective',
  'messenger',
  'tournament',
  'consideration',
  'catalogue',
  'antonio',
  'terminal',
  'publishers',
  'caribbean',
  'liquid',
  'nebraska',
  'salary',
  'reservation',
  'properly',
  'remaining',
  'avatar',
  'depending',
  'illegal',
  'expansion',
  'tomorrow',
  'purchased',
  'visiting',
  'performing',
  'keyboard',
  'collected',
  'suitable',
  'absolutely',
  'guinea',
  'featuring',
  'implement',
  'scheduled',
  'typical',
  'calculator',
  'significantly',
  'temporary',
  'sufficient',
  'province',
  'catholic',
  'awareness',
  'contribution',
  'measurement',
  'formula',
  'constitution',
  'packaging',
  'pakistan',
  'reliable',
  'consultation',
  'unable',
  'democracy',
  'wallpaper',
  'merchandise',
  'resistance',
  'memorial',
  'visitor',
  'baltimore',
  'gateway',
  'alumni',
  'candidates',
  'charlotte',
  'ordered',
  'biological',
  'transition',
  'preferences',
  'romance',
  'instrument',
  'classification',
  'focused',
  'physician',
  'hollywood',
  'wikipedia',
  'cellular',
  'normally',
  'spiritual',
  'diabetes',
  'photographs',
  'flexible',
  'favourites',
  'relatively',
  'satisfaction',
  'represents',
  'indexed',
  'preferred',
  'intellectual',
  'comfortable',
  'magnetic',
  'interaction',
  'listening',
  'effectively',
  'registry',
  'piano',
  'experimental',
  'revolution',
  'consolidation',
  'allowing',
  'mystery',
  'landscape',
  'dependent',
  'mechanical',
  'delaware',
  'consultants',
  'applicant',
  'barbara',
  'cooperation',
  'acquisition',
  'implemented',
  'directories',
  'recognized',
  'notification',
  'occurred',
  'diversity',
  'cleveland',
  'reverse',
  'deposit',
  'seminar',
  'investments',
  'latina',
  'specify',
  'accessibility',
  'sensitive',
  'templates',
  'concrete',
  'editing',
  'completion',
  'upload',
  'universities',
  'technique',
  'contractors',
  'subscriptions',
  'calculate',
  'alexander',
  'broadcast',
  'converted',
  'toshiba',
  'anniversary',
  'improvements',
  'specification',
  'accident',
  'accessible',
  'accessory',
  'resident',
  'possibly',
  'typically',
  'representation',
  'arrangements',
  'conferences',
  'uniprotkb',
  'consumption',
  'birmingham',
  'afternoon',
  'consultant',
  'controller',
  'ownership',
  'committees',
  'legislative',
  'researchers',
  'malaysia',
  'unsubscribe',
  'antique',
  'molecular',
  'residence',
  'attorneys',
  'antiques',
  'density',
  'operators',
  'sustainable',
  'philippines',
  'statistical',
  'innovation',
  'parallel',
  'definitions',
  'enhanced',
  'entitled',
  'severe',
  'generate',
  'newspapers',
  'hospitals',
  'monitors',
  'exception',
  'duration',
  'successfully',
  'indonesia',
  'pursuant',
  'primarily',
  'capabilities',
  'recommendation',
  'recruitment',
  'organized',
  'adoption',
  'improving',
  'expensive',
  'buffalo',
  'organisations',
  'explained',
  'programmes',
  'expertise',
  'mechanism',
  'jewellery',
  'eventually',
  'measured',
  'agreements',
  'considering',
  'innovative',
  'conclusion',
  'disorders',
  'collaboration',
  'hamilton',
  'detection',
  'formation',
  'tutorial',
  'engineers',
  'entity',
  'cruises',
  'proposals',
  'moderator',
  'tutorials',
  'settlement',
  'portugal',
  'lawrence',
  'valuable',
  'collectables',
  'fantastic',
  'imagine',
  'purchasing',
  'stereo',
  'appointed',
  'operational',
  'corresponding',
  'descriptions',
  'determination',
  'animation',
  'oracle',
  'productions',
  'aviation',
  'disaster',
  'telecommunications',
  'instructor',
  'achieved',
  'injuries',
  'approaches',
  'anthony',
  'nintendo',
  'usual',
  'loading',
  'appeared',
  'designers',
  'melbourne',
  'imaging',
  'argentina',
  'possibility',
  'analyst',
  'commissioner',
  'dangerous',
  'exciting',
  'reliability',
  'unfortunately',
  'respectively',
  'volunteers',
  'attachment',
  'ringtone',
  'derived',
  'oriented',
  'columbus',
  'appointment',
  'hurricane',
  'quiet',
  'represented',
  'mortgages',
  'responsibilities',
  'carefully',
  'productivity',
  'investors',
  'underground',
  'diagnosis',
  'principle',
  'vacations',
  'semester',
  'calculated',
  'casinos',
  'appearance',
  'apache',
  'incorporated',
  'algorithm',
  'familiar',
  'capable',
  'debian',
  'involving',
  'investing',
  'christopher',
  'admission',
  'victory',
  'madison',
  'terrorism',
  'editions',
  'parliament',
  'situations',
  'allocated',
  'vertical',
  'corrections',
  'structural',
  'municipal',
  'describes',
  'absolute',
  'disabilities',
  'anytime',
  'substance',
  'prohibited',
  'addressed',
  'guardian',
  'simulation',
  'initiatives',
  'concentration',
  'interpretation',
  'bankruptcy',
  'optimization',
  'substances',
  'discovered',
  'genetic',
  'restrictions',
  'participating',
  'exhibition',
  'evaluate',
  'tiffany',
  'tropical',
  'composition',
  'nationwide',
  'definitely',
  'existence',
  'commentary',
  'limousines',
  'developments',
  'immigration',
  'destinations',
  'mutual',
  'pipeline',
  'necessarily',
  'attribute',
  'everyday',
  'apparently',
  'surrounding',
  'popularity',
  'inquiry',
  'ethernet',
  'exhibit',
  'visible',
  'postposted',
  'coordinator',
  'obviously',
  'mercury',
  'navigate',
  'fundamental',
  'substantial',
  'progressive',
  'cialis',
  'sacramento',
  'impossible',
  'depression',
  'platinum',
  'awarded',
  'testimonials',
  'memorabilia',
  'cartridge',
  'alberta',
  'explanation',
  'cincinnati',
  'subsection',
  'electricity',
  'permitted',
  'arrival',
  'pottery',
  'emphasis',
  'workplace',
  'mexican',
  'confirmed',
  'wallpapers',
  'desired',
  'infection',
  'expense',
  'eligibility',
  'entering',
  'involvement',
  'placement',
  'observations',
  'vbulletin',
  'subsequent',
  'motorcycle',
  'disclosure',
  'establishment',
  'memories',
  'arrived',
  'adequate',
  'senator',
  'presentations',
  'entirely',
  'replaced',
  'undergraduate',
  'reducing',
  'occupation',
  'donations',
  'associations',
  'citysearch',
  'closely',
  'radiation',
  'diary',
  'seriously',
  'elsewhere',
  'pollution',
  'conservative',
  'effectiveness',
  'abroad',
  'ebony',
  'visited',
  'demonstrate',
  'atmosphere',
  'operated',
  'experiment',
  'federation',
  'invited',
  'assignment',
  'chemicals',
  'absence',
  'surprise',
  'partial',
  'everybody',
  'nashville',
  'counseling',
  'acceptable',
  'satisfied',
  'measurements',
  'verify',
  'medication',
  'supposed',
  'ordinary',
  'nobody',
  'violation',
  'configure',
  'stability',
  'institutional',
  'expectations',
  'independence',
  'reporter',
  'metabolism',
  'personally',
  'sentence',
  'maria',
  'uniform',
  'excellence',
  'attributes',
  'recognize',
  'survival',
  'jonathan',
  'forgotten',
  'acrobat',
  'athletic',
  'behaviour',
  'coastal',
  'charity',
  'intelligent',
  'edinburgh',
  'obligation',
  'hungary',
  'traveler',
  'regardless',
  'aluminum',
  'restricted',
  'republican',
  'syndrome',
  'attendance',
  'penalty',
  'enables',
  'iraqi',
  'builder',
  'jessica',
  'arguments',
  'amsterdam',
  'adventures',
  'announcement',
  'appreciate',
  'expanded',
  'casual',
  'bulgaria',
  'regularly',
  'believed',
  'mechanisms',
  'divorce',
  'customize',
  'tradition',
  'exposed',
  'telecom',
  'deputy',
  'indicators',
  'emissions',
  'physicians',
  'loaded',
  'experiments',
  'afghanistan',
  'scholarship',
  'governance',
  'supplements',
  'camcorder',
  'implementing',
  'ourselves',
  'tobacco',
  'incident',
  'dynamics',
  'conversation',
  'revenues',
  'capability',
  'producing',
  'precision',
  'reserves',
  'contributed',
  'reproduction',
  'minority',
  'ingredients',
  'recorder',
  'promotions',
  'rehabilitation',
  'maintaining',
  'defence',
  'environments',
  'divided',
  'reception',
  'correctly',
  'seminars',
  'consequences',
  'geography',
  'appearing',
  'integrity',
  'discrimination',
  'legacy',
  'vitamin',
  'processed',
  'genuine',
  'implications',
  'functionality',
  'paradise',
  'intermediate',
  'emotional',
  'geographic',
  'preliminary',
  'introduce',
  'promotional',
  'romantic',
  'revealed',
  'specialists',
  'generator',
  'examine',
  'suspension',
  'correction',
  'authentication',
  'communicate',
  'supplement',
  'promoting',
  'machinery',
  'equation',
  'probability',
  'admissions',
  'involves',
  'quarterly',
  'illustrated',
  'continental',
  'alternate',
  'achievement',
  'limitations',
  'automated',
  'passenger',
  'convenient',
  'noticed',
  'literary',
  'orientation',
  'analog',
  'facial',
  'flexibility',
  'boundary',
  'jurisdiction',
  'equipped',
  'encouraged',
  'broadway',
  'acquired',
  'cartridges',
  'entrance',
  'declaration',
  'automation',
  'advantages',
  'induced',
  'preparing',
  'recipient',
  'extensions',
  'islamic',
  'athletics',
  'alternatives',
  'determining',
  'lebanon',
  'personalized',
  'kennedy',
  'conditioning',
  'secured',
  'unusual',
  'answered',
  'partnerships',
  'destruction',
  'increasingly',
  'migration',
  'disorder',
  'basically',
  'conventional',
  'applicants',
  'habitat',
  'median',
  'occupational',
  'animated',
  'judicial',
  'adjustment',
  'integer',
  'bachelor',
  'attitude',
  'camcorders',
  'engaged',
  'binary',
  'genetics',
  'attended',
  'difficulty',
  'collective',
  'coalition',
  'enrollment',
  'producers',
  'collector',
  'interfaces',
  'advertisers',
  'representing',
  'observation',
  'deleted',
  'restoration',
  'convenience',
  'returning',
  'opposition',
  'defendant',
  'confirmation',
  'embedded',
  'supervisor',
  'peripherals',
  'liable',
  'bestsellers',
  'petition',
  'antenna',
  'assumed',
  'departure',
  'minneapolis',
  'bikini',
  'diameter',
  'ottawa',
  'interactions',
  'literacy',
  'intervention',
  'attraction',
  'modification',
  'latinas',
  'customized',
  'involve',
  'opposite',
  'understood',
  'rapidly',
  'mercedes',
  'assurance',
  'happening',
  'tramadol',
  'metropolitan',
  'compilation',
  'verification',
  'veterans',
  'attractive',
  'occasion',
  'recordings',
  'jefferson',
  'gardening',
  'obligations',
  'orchestra',
  'moreover',
  'minimal',
  'polyphonic',
  'lottery',
  'outsourcing',
  'adjustable',
  'allocation',
  'michelle',
  'discipline',
  'demonstrated',
  'dialogue',
  'identifying',
  'alphabetical',
  'declared',
  'dispatched',
  'disposal',
  'installing',
  'romania',
  'voluntary',
  'commonly',
  'photographer',
  'messaging',
  'quantum',
  'largely',
  'announce',
  'constructed',
  'additions',
  'requiring',
  'engagement',
  'refinance',
  'inspired',
  'suddenly',
  'merely',
  'arrangement',
  'conclusions',
  'bibliography',
  'compatibility',
  'furthermore',
  'cooperative',
  'netscape',
  'measuring',
  'robinson',
  'jacksonville',
  'headquarters',
  'olympic',
  'transformation',
  'remained',
  'attachments',
  'entities',
  'administrators',
  'personality',
  'israeli',
  'medicare',
  'facilitate',
  'subscriber',
  'jamaica',
  'priorities',
  'bookstore',
  'incredible',
  'donation',
  'commonwealth',
  'pharmaceutical',
  'manhattan',
  'katrina',
  'workforce',
  'targeted',
  'organizational',
  'realized',
  'portuguese',
  'adverse',
  'everywhere',
  'discharge',
  'albany',
  'hazardous',
  'methodology',
  'somebody',
  'reputation',
  'resistant',
  'democrats',
  'recycling',
  'qualifications',
  'slideshow',
  'variation',
  'transferred',
  'photograph',
  'oakland',
  'colombia',
  'distributor',
  'underlying',
  'suicide',
  'archived',
  'photoshop',
  'arabia',
  'gathering',
  'projection',
  'juice',
  'mathematical',
  'logical',
  'specialized',
  'diagnostic',
  'panama',
  'indianapolis',
  'corporations',
  'courtesy',
  'criticism',
  'automobile',
  'confidential',
  'statutory',
  'accommodations',
  'downloaded',
  'retired',
  'detected',
  'arising',
  'bracelet',
  'injection',
  'yorkshire',
  'populations',
  'protective',
  'acoustic',
  'cassette',
  'initially',
  'indicator',
  'eliminate',
  'sunglasses',
  'preference',
  'cemetery',
  'croatia',
  'venezuela',
  'exploration',
  'sequences',
  'astronomy',
  'announces',
  'berkeley',
  'adjusted',
  'compression',
  'seasonal',
  'establishing',
  'constitutional',
  'perfectly',
  'instantly',
  'coaching',
  'examined',
  'encoding',
  'litigation',
  'submissions',
  'broadcasting',
  'horizontal',
  'cosmetic',
  'resulted',
  'terrorist',
  'informational',
  'ethical',
  'ecommerce',
  'mobility',
  'builders',
  'suffering',
  'neutral',
  'prospective',
  'ultimately',
  'equally',
  'artificial',
  'spectacular',
  'coordination',
  'connector',
  'guilty',
  'affiliated',
  'activation',
  'naturally',
  'subscribers',
  'mitsubishi',
  'underwear',
  'potentially',
  'inclusive',
  'considerable',
  'resolved',
  'mozilla',
  'anymore',
  'locator',
  'selecting',
  'processors',
  'pantyhose',
  'zimbabwe',
  'difficulties',
  'complexity',
  'constantly',
  'resolve',
  'barcelona',
  'presidential',
  'documentary',
  'territories',
  'melissa',
  'palestinian',
  'nigeria',
  'ensuring',
  'hispanic',
  'legislature',
  'hospitality',
  'anybody',
  'procurement',
  'diamonds',
  'untitled',
  'theoretical',
  'referral',
  'surveillance',
  'optimal',
  'protocols',
  'substitute',
  'inclusion',
  'hopefully',
  'brilliant',
  'reuters',
  'evaluated',
  'assignments',
  'manuals',
  'termination',
  'authentic',
  'montgomery',
  'architectural',
  'louisville',
  'macintosh',
  'monica',
  'amenities',
  'virtually',
  'colored',
  'authorization',
  'verified',
  'formerly',
  'projector',
  'situated',
  'comparative',
  'psychological',
  'surprised',
  'vitamins',
  'renewal',
  'genealogy',
  'opposed',
  'expenditure',
  'connectivity',
  'algorithms',
  'similarly',
  'collaborative',
  'excluding',
  'madonna',
  'commander',
  'suggestion',
  'interval',
  'investigate',
  'expansys',
  'repeated',
  'connecting',
  'logistics',
  'proportion',
  'florence',
  'analyses',
  'significance',
  'scenario',
  'atomic',
  'symposium',
  'arabic',
  'essentials',
  'protecting',
  'transmitted',
  'intensive',
  'deviant',
  'adapters',
  'correspondence',
  'supervision',
  'expenditures',
  'separation',
  'testimony',
  'celebrities',
  'mandatory',
  'boundaries',
  'crucial',
  'syndication',
  'celebration',
  'adjacent',
  'filtering',
  'tuition',
  'exotic',
  'damaged',
  'receptor',
  'surgical',
  'citation',
  'offensive',
  'imperial',
  'benjamin',
  'deployment',
  'colleagues',
  'olympus',
  'separated',
  'upgrades',
  'grocery',
  'offshore',
  'retailers',
  'harrison',
  'occasionally',
  'attending',
  'emission',
  'recruiting',
  'apparent',
  'instructional',
  'traveling',
  'permissions',
  'biotechnology',
  'excited',
  'balanced',
  'prescribed',
  'catherine',
  'reproduced',
  'explicit',
  'calculation',
  'consolidated',
  'exclude',
  'occasions',
  'equations',
  'exceptional',
  'anxiety',
  'spatial',
  'respondents',
  'ceramic',
  'annually',
  'considerations',
  'delivers',
  'necklace',
  'musicians',
  'composite',
  'unavailable',
  'arranged',
  'advocacy',
  'essentially',
  'designing',
  'qualify',
  'assessments',
  'diagram',
  'peoples',
  'removing',
  'sensitivity',
  'preservation',
  'isolated',
  'calgary',
  'interim',
  'assisted',
  'approve',
  'intensity',
  'technological',
  'syndicate',
  'abortion',
  'dialog',
  'antivirus',
  'addressing',
  'discounted',
  'indians',
  'membrane',
  'bangladesh',
  'constitute',
  'locally',
  'concluded',
  'desperate',
  'demonstration',
  'governmental',
  'manufactured',
  'graduation',
  'variations',
  'addiction',
  'morocco',
  'refused',
  'exterior',
  'ecology',
  'botswana',
  'synthesis',
  'undefined',
  'unemployment',
  'verizon',
  'enhancement',
  'newcastle',
  'velocity',
  'composed',
  'performances',
  'oasis',
  'societies',
  'silicon',
  'brazilian',
  'identical',
  'petroleum',
  'compete',
  'norwegian',
  'honolulu',
  'retention',
  'exchanges',
  'wondering',
  'profession',
  'separately',
  'physiology',
  'collecting',
  'omaha',
  'participant',
  'scholarships',
  'recreational',
  'dominican',
  'treasury',
  'solaris',
  'occupied',
  'observer',
  'ceremony',
  'arrested',
  'expanding',
  'provincial',
  'investigations',
  'yamaha',
  'medications',
  'rochester',
  'solomon',
  'assessed',
  'advertiser',
  'enabling',
  'encryption',
  'downloadable',
  'sophisticated',
  'imposed',
  'possession',
  'laboratories',
  'advances',
  'vegetables',
  'respondent',
  'statute',
  'rejected',
  'satisfy',
  'manufacture',
  'guided',
  'vulnerability',
  'accountability',
  'celebrate',
  'accredited',
  'appliance',
  'compressed',
  'bahamas',
  'scheduling',
  'perspectives',
  'mortality',
  'christians',
  'therapeutic',
  'impressive',
  'accordingly',
  'architect',
  'railroad',
  'challenging',
  'nursery',
  'microwave',
  'accidents',
  'travesti',
  'relocation',
  'contributors',
  'salvador',
  'violations',
  'temperatures',
  'competitions',
  'discretion',
  'tanzania',
  'preserve',
  'unsigned',
  'cosmetics',
  'theories',
  'repository',
  'jeremy',
  'concentrations',
  'estonia',
  'christianity',
  'veteran',
  'executed',
  'negotiations',
  'realistic',
  'integral',
  'namibia',
  'generating',
  'christina',
  'congressional',
  'synopsis',
  'reunion',
  'photographic',
  'ecuador',
  'accessed',
  'modifications',
  'imported',
  'acquire',
  'contrary',
  'millennium',
  'focusing',
  'admitted',
  'equality',
  'samoa',
  'achieving',
  'fisheries',
  'exceptions',
  'macromedia',
  'companion',
  'analyze',
  'divisions',
  'additionally',
  'fellowship',
  'invalid',
  'martial',
  'victorian',
  'cambodia',
  'copyrights',
  'lithuania',
  'mastercard',
  'chronicles',
  'obtaining',
  'updating',
  'distribute',
  'decorative',
  'kijiji',
  'confused',
  'enlargement',
  'accused',
  'unity',
  'conjunction',
  'instances',
  'indigenous',
  'brunette',
  'validation',
  'corruption',
  'incentives',
  'cholesterol',
  'gathered',
  'slovenia',
  'notified',
  'differential',
  'dramatic',
  'terrible',
  'starsmerchant',
  'arthritis',
  'nevertheless',
  'taxation',
  'cuisine',
  'practitioners',
  'theorem',
  'inflation',
  'contracting',
  'structured',
  'reasonably',
  'radical',
  'graduates',
  'recommends',
  'controlling',
  'reload',
  'distributors',
  'levitra',
  'assuming',
  'monetary',
  'elderly',
  'arlington',
  'particles',
  'floating',
  'extraordinary',
  'indicating',
  'bolivia',
  'coordinate',
  'exclusively',
  'emily',
  'alleged',
  'limitation',
  'illustration',
  'inquiries',
  'inspiration',
  'affecting',
  'eclipse',
  'downloading',
  'aggregate',
  'actively',
  'ciao',
  'complicated',
  'shopzilla',
  'injured',
  'decorating',
  'expressions',
  'uploaded',
  'collapse',
  'americas',
  'connectors',
  'unlikely',
  'beverage',
  'latvia',
  'travelers',
  'rarely',
  'infected',
  'offerings',
  'genesis',
  'incorrect',
  'bicycle',
  'furnishings',
  'guatemala',
  'particle',
  'perception',
  'humidity',
  'renaissance',
  'pathology',
  'ordinance',
  'photographers',
  'infections',
  'configured',
  'survive',
  'festivals',
  'possibilities',
  'amino',
  'contributing',
  'manitoba',
  'analytical',
  'circulation',
  'offense',
  'assumption',
  'jerusalem',
  'transexuales',
  'invention',
  'nickname',
  'technician',
  'executives',
  'enquiries',
  'cognitive',
  'exploring',
  'enquiry',
  'intense',
  'registrar',
  'supporters',
  'statutes',
  'withdrawal',
  'predicted',
  'saskatchewan',
  'cancellation',
  'enrolled',
  'ministers',
  'publicly',
  'geneva',
  'veterinary',
  'reseller',
  'suffered',
  'informal',
  'relevance',
  'incentive',
  'butterfly',
  'mechanics',
  'heavily',
  'numerical',
  'defining',
  'reflection',
  'accompanied',
  'invitation',
  'devoted',
  'princeton',
  'spirituality',
  'proprietary',
  'timothy',
  'thumbzilla',
  'medieval',
  'porcelain',
  'pichunter',
  'captured',
  'translated',
  'columnists',
  'diploma',
  'innocent',
  'consensus',
  'delivering',
  'patricia',
  'uganda',
  'journalism',
  'trivia',
  'adidas',
  'intention',
  'syria',
  'disagree',
  'undertaken',
  'statewide',
  'semiconductor',
  'gregory',
  'episodes',
  'circular',
  'illustrations',
  'interact',
  'happiness',
  'substantially',
  'bizarre',
  'olympics',
  'identifier',
  'calculations',
  'conducting',
  'suzuki',
  'trinidad',
  'accomplished',
  'calculators',
  'geometry',
  'impression',
  'slovakia',
  'correlation',
  'gorgeous',
  'capitol',
  'barbados',
  'fragrance',
  'replica',
  'transparent',
  'trinity',
  'charleston',
  'selections',
  'projectors',
  'inappropriate',
  'comparing',
  'vocational',
  'davidson',
  'scotia',
  'pharmacies',
  'introducing',
  'organize',
  'appreciated',
  'latino',
  'albuquerque',
  'harmony',
  'distinguished',
  'projected',
  'assumptions',
  'shareholders',
  'developmental',
  'regulated',
  'triangle',
  'anticipated',
  'oriental',
  'zambia',
  'completing',
  'comparable',
  'advocate',
  'confusion',
  'copyrighted',
  'warranties',
  'documented',
  'paperbacks',
  'coaches',
  'keyboards',
  'vulnerable',
  'arrange',
  'artistic',
  'reflected',
  'unified',
  'detector',
  'ignored',
  'respiratory',
  'notifications',
  'transexual',
  'evaluating',
  'subcommittee',
  'maternity',
  'colonial',
  'embassy',
  'journalists',
  'rebecca',
  'indirect',
  'foundations',
  'volleyball',
  'horizon',
  'marina',
  'liabilities',
  'bosnia',
  'decreased',
  'tolerance',
  'creativity',
  'describing',
  'quotations',
  'inspector',
  'disable',
  'leonard',
  'reminder',
  'behavioral',
  'abilities',
  'initiated',
  'sullivan',
  'nonprofit',
  'lancaster',
  'suspended',
  'hereby',
  'observe',
  'julia',
  'containers',
  'attitudes',
  'simultaneously',
  'racial',
  'integrate',
  'bermuda',
  'amanda',
  'sociology',
  'exhibitions',
  'confident',
  'retrieved',
  'exhibits',
  'officially',
  'consortium',
  'bacteria',
  'recipients',
  'delicious',
  'traditions',
  'periodically',
  'durable',
  'hungarian',
  'referring',
  'symantec',
  'wichita',
  'uruguay',
  'educators',
  'vegetable',
  'humanities',
  'independently',
  'custody',
  'alignment',
  'henderson',
  'britannica',
  'competitors',
  'visibility',
  'latitude',
  'consciousness',
  'deposits',
  'beverly',
  'encounter',
  'resolutions',
  'accessing',
  'attempted',
  'analysts',
  'queue',
  'nicholas',
  'invasion',
  'witnesses',
  'administered',
  'fujitsu',
  'frederick',
  'medicaid',
  'infrared',
  'aggressive',
  'advertisements',
  'quarters',
  'disturbed',
  'determines',
  'sculpture',
  'naturals',
  'motivation',
  'pharmacology',
  'passengers',
  'quantities',
  'petersburg',
  'consistently',
  'obituaries',
  'punishment',
  'appreciation',
  'subsequently',
  'belarus',
  'gravity',
  'providence',
  'restriction',
  'incorporate',
  'guitars',
  'essence',
  'ethiopia',
  'athletes',
  'humanity',
  'transcription',
  'complications',
  'remembered',
  'galaxy',
  'synthetic',
  'testament',
  'dominant',
  'specifics',
  'partially',
  'minimize',
  'wilderness',
  'generations',
  'tournaments',
  'anatomy',
  'sponsorship',
  'proceeding',
  'volkswagen',
  'uncertainty',
  'reconstruction',
  'subsidiary',
  'clarity',
  'encouraging',
  'furnished',
  'monaco',
  'emirates',
  'terrorists',
  'comparisons',
  'beneficial',
  'distributions',
  'viewpicture',
  'threatened',
  'minolta',
  'republicans',
  'discusses',
  'responded',
  'ivory',
  'prediction',
  'pharmaceuticals',
  'fabulous',
  'alias',
  'thesaurus',
  'individually',
  'battlefield',
  'literally',
  'ecological',
  'appraisal',
  'consisting',
  'maritime',
  'periodic',
  'submitting',
  'citations',
  'geographical',
  'mozambique',
  'envelope',
  'diane',
  'disclaimers',
  'championships',
  'excluded',
  'finishing',
  'emotions',
  'wellington',
  'incoming',
  'bulgarian',
  'aboriginal',
  'remarkable',
  'rotation',
  'preventing',
  'productive',
  'eugene',
  'compliant',
  'penalties',
  'imagination',
  'refurbished',
  'joshua',
  'armenia',
  'activated',
  'conferencing',
  'politicians',
  'accommodate',
  'aurora',
  'dietary',
  'symphony',
  'accepting',
  'precipitation',
  'marilyn',
  'isolation',
  'sustained',
  'approximate',
  'programmer',
  'inherited',
  'incomplete',
  'chronicle',
  'legitimate',
  'biographies',
  'investigator',
  'prisoners',
  'mediterranean',
  'nightlife',
  'architects',
  'entrepreneur',
  'freelance',
  'excessive',
  'helena',
  'regarded',
  'valuation',
  'unexpected',
  'cigarette',
  'characteristic',
  'egyptian',
  'tunisia',
  'metallica',
  'outlined',
  'consequently',
  'appointments',
  'narrative',
  'enormous',
  'academics',
  'quantitative',
  'screensavers',
  'subdivision',
  'distinction',
  'honduras',
  'insured',
  'sustainability',
  'cabinets',
  'algebra',
  'formatting',
  'nutritional',
  'nicaragua',
  'readily',
  'affiliation',
  'infinite',
  'diana',
  'relatives',
  'legally',
  'satisfactory',
  'revolutionary',
  'bracelets',
  'civilian',
  'telephony',
  'remedy',
  'adjustments',
  'graphical',
  'discussing',
  'aerospace',
  'meaningful',
  'adapted',
  'estates',
  'democrat',
  'retained',
  'voyeurweb',
  'extending',
  'specifies',
  'logitech',
  'accreditation',
  'blackberry',
  'meditation',
  'modular',
  'microphone',
  'macedonia',
  'combining',
  'instrumental',
  'organizing',
  'moderators',
  'kazakhstan',
  'hawaiian',
  'partition',
  'invisible',
  'gratuit',
  'translations',
  'jaguar',
  'commodity',
  'kilometers',
  'thanksgiving',
  'guarantees',
  'cylinder',
  'indication',
  'congratulations',
  'cigarettes',
  'revenge',
  'controllers',
  'aqua',
  'consultancy',
  'finances',
  'conventions',
  'italiano',
  'coordinates',
  'carnival',
  'responding',
  'physically',
  'stakeholders',
  'hydrocodone',
  'consecutive',
  'deserve',
  'attempting',
  'representations',
  'competing',
  'peninsula',
  'accurately',
  'considers',
  'catalogs',
  'ministries',
  'vacancies',
  'quizzes',
  'parliamentary',
  'lucia',
  'savannah',
  'deficit',
  'metadata',
  'circuits',
  'incurred',
  'acknowledge',
  'thoroughly',
  'antigua',
  'nottingham',
  'identifies',
  'questionnaire',
  'qualification',
  'miniature',
  'interstate',
  'aerial',
  'consequence',
  'systematic',
  'perceived',
  'origins',
  'madagascar',
  'tobago',
  'presenting',
  'troubleshooting',
  'uzbekistan',
  'indexes',
  'centuries',
  'magnitude',
  'richardson',
  'fragrances',
  'vocabulary',
  'earthquake',
  'fundraising',
  'albania',
  'geological',
  'assessing',
  'introduces',
  'webmasters',
  'computational',
  'acdbentity',
  'participated',
  'answering',
  'impressed',
  'conspiracy',
  'theology',
  'evident',
  'organizer',
  'allergy',
  'combinations',
  'preceding',
  'cumulative',
  'edmonton',
  'amplifier',
  'arbitrary',
  'prominent',
  'lexington',
  'titanium',
  'contacted',
  'recorders',
  'occasional',
  'deutsche',
  'innovations',
  'algeria',
  'reviewing',
  'potato',
  'explicitly',
  'leone',
  'transsexual',
  'citizenship',
  'lawsuit',
  'informative',
  'hierarchy',
  'influenced',
  'abandoned',
  'italic',
  'complement',
  'mauritius',
  'requesting',
  'scenarios',
  'hitachi',
  'extraction',
  'elevation',
  'utilization',
  'beverages',
  'calibration',
  'efficiently',
  'entertaining',
  'prerequisite',
  'hypothesis',
  'medicines',
  'altered',
  'pontiac',
  'regression',
  'doctrine',
  'enhancements',
  'renewable',
  'intersection',
  'consistency',
  'collectors',
  'conclude',
  'recognised',
  'azerbaijan',
  'astrology',
  'advisors',
  'pavilion',
  'occurring',
  'supplemental',
  'travelling',
  'induction',
  'precisely',
  'provinces',
  'paraguay',
  'refined',
  'incidence',
  'boutique',
  'acrylic',
  'incidents',
  'peterson',
  'enhancing',
  'horrible',
  'metallic',
  'interference',
  'listprice',
  'cadillac',
  'atmospheric',
  'malawi',
  'knowledgestorm',
  'referenced',
  'publicity',
  'marathon',
  'proposition',
  'gasoline',
  'niagara',
  'catalyst',
  'allowance',
  'denial',
  'designation',
  'duplicate',
  'criterion',
  'civilization',
  'analyzed',
  'vietnamese',
  'tremendous',
  'remedies',
  'validity',
  'angola',
  'corrected',
  'salaries',
  'encountered',
  'tsunami',
  'internationally',
  'surrounded',
  'expedia',
  'geology',
  'coating',
  'commented',
  'accomplish',
  'boating',
  'shakira',
  'broader',
  'vegetarian',
  'newfoundland',
  'investigated',
  'ambassador',
  'coated',
  'stephanie',
  'contacting',
  'vegetation',
  'findarticles',
  'louise',
  'specially',
  'aquatic',
  'reliance',
  'infectious',
  'ferrari',
  'continuity',
  'phenomenon',
  'ensemble',
  'insulin',
  'assured',
  'biblical',
  'mysimon',
  'utilize',
  'adaptor',
  'angela',
  'referrals',
  'differently',
  'organised',
  'musician',
  'integrating',
  'limiting',
  'revisions',
  'papua',
  'restored',
  'reasoning',
  'rendered',
  'charitable',
  'annotated',
  'convinced',
  'burlington',
  'replacing',
  'researcher',
  'occupations',
  'acknowledged',
  'equilibrium',
  'inquire',
  'characterized',
  'aruba',
  'cottages',
  'privilege',
  'develops',
  'qualifying',
  'estimation',
  'pediatric',
  'techrepublic',
  'institutes',
  'traveller',
  'appropriations',
  'suspected',
  'tomatoes',
  'beginners',
  'instructors',
  'highlighted',
  'stationery',
  'unauthorized',
  'antibody',
  'competent',
  'momentum',
  'contributor',
  'demonstrates',
  'grateful',
  'gradually',
  'desirable',
  'journalist',
  'abraham',
  'afterwards',
  'religions',
  'senegal',
  'explosion',
  'signatures',
  'disciplines',
  'conversations',
  'nvidia',
  'opponent',
  'simplified',
  'motherboard',
  'bibliographic',
  'champagne',
  'decimal',
  'deviation',
  'superintendent',
  'propecia',
  'mongolia',
  'penguin',
  'magical',
  'influences',
  'inspections',
  'irrigation',
  'miracle',
  'manually',
  'hydraulic',
  'centered',
  'penetration',
  'conviction',
  'omissions',
  'retrieval',
  'qualities',
  'prototype',
  'importantly',
  'petite',
  'apparatus',
  'explaining',
  'nomination',
  'empirical',
  'rotary',
  'dependence',
  'discrete',
  'beginner',
  'sexuality',
  'polyester',
  'commitments',
  'suggesting',
  'remainder',
  'privileges',
  'televisions',
  'specializing',
  'commodities',
  'serbia',
  'declined',
  'motorcycles',
  'concentrate',
  'intimate',
  'reproductive',
  'refrigerator',
  'intervals',
  'sentences',
  'exclusion',
  'workstation',
  'holocaust',
  'receivers',
  'customise',
  'disposition',
  'variance',
  'navigator',
  'investigators',
  'marijuana',
  'adaptive',
  'computed',
  'cathedral',
  'nirvana',
  'invision',
  'destiny',
  'generous',
  'fascinating',
  'landscapes',
  'lafayette',
  'computation',
  'cardiovascular',
  'cardiac',
  'salvation',
  'adrian',
  'predictions',
  'accompanying',
  'vatican',
  'selective',
  'arbitration',
  'configuring',
  'editorials',
  'sacrifice',
  'removable',
  'convergence',
  'gibraltar',
  'suited',
  'anthropology',
  'emperor',
  'malpractice',
  'educated',
  'reporters',
  'necessity',
  'rendering',
  'inserted',
  'obesity',
  'suburban',
  'hepatitis',
  'nationally',
  'tomato',
  'andorra',
  'expired',
  'specialties',
  'humanitarian',
  'invitations',
  'functioning',
  'survivor',
  'garcia',
  'cingular',
  'economies',
  'alexandria',
  'bacterial',
  'undertake',
  'continuously',
  'impaired',
  'achievements',
  'convertible',
  'tragedy',
  'julian',
  'tribunal',
  'secretariat',
  'paragraphs',
  'adolescent',
  'nominations',
  'cancelled',
  'banana',
  'introductory',
  'reservoir',
  'occurrence',
  'worcester',
  'demographic',
  'disciplinary',
  'respected',
  'disclose',
  'interpreted',
  'evaluations',
  'elimination',
  'hypothetical',
  'immigrants',
  'complimentary',
  'helicopter',
  'performer',
  'commissions',
  'powerseller',
  'graduated',
  'endorsed',
  'surprising',
  'italia',
  'unnecessary',
  'dramatically',
  'liberia',
  'maximize',
  'senators',
  'yugoslavia',
  'characterization',
  'likelihood',
  'fundamentals',
  'contamination',
  'endangered',
  'compromise',
  'optimize',
  'caroline',
  'expiration',
  'peripheral',
  'engaging',
  'negotiation',
  'opponents',
  'nominated',
  'confidentiality',
  'electoral',
  'changelog',
  'deferred',
  'alternatively',
  'polished',
  'greensboro',
  'controversial',
  'recovered',
  'justify',
  'upgrading',
  'demanding',
  'defensive',
  'subaru',
  'forbidden',
  'vanilla',
  'programmers',
  'monitored',
  'installations',
  'deutschland',
  'arrivals',
  'practitioner',
  'motivated',
  'smithsonian',
  'securely',
  'examining',
  'revelation',
  'pursuit',
  'delegation',
  'dictionaries',
  'transparency',
  'figured',
  'currencies',
  'survivors',
  'positioning',
  'colony',
  'promoted',
  'moldova',
  'descending',
  'enclosed',
  'temporarily',
  'frequencies',
  'reflections',
  'mariah',
  'municipality',
  'detective',
  'surgeon',
  'experiencing',
  'endorsement',
  'intranet',
  'psychiatry',
  'persistent',
  'deborah',
  'summaries',
  'auditor',
  'aquarium',
  'magnificent',
  'colleague',
  'adaptation',
  'sexually',
  'enclosure',
  'dividend',
  'supervisors',
  'westminster',
  'distances',
  'absorption',
  'neural',
  'apollo',
  'disappointed',
  'persian',
  'continually',
  'communist',
  'collectible',
  'handmade',
  'entrepreneurs',
  'grenada',
  'acquisitions',
  'biodiversity',
  'excitement',
  'somalia',
  'presently',
  'mysterious',
  'librarian',
  'subsidiaries',
  'indonesian',
  'therapist',
  'promising',
  'relaxation',
  'erotica',
  'temporal',
  'commissioners',
  'forwarding',
  'nightmare',
  'airplane',
  'reductions',
  'southampton',
  'istanbul',
  'organisms',
  'telescope',
  'asbestos',
  'advancement',
  'harassment',
  'existed',
  'generators',
  'generates',
  'potatoes',
  'replication',
  'inexpensive',
  'receptors',
  'optimum',
  'interventions',
  'huntington',
  'syracuse',
  'internship',
  'aluminium',
  'snowboard',
  'beastality',
  'evanescence',
  'coordinated',
  'firmware',
  'antarctica',
  'canberra',
  'chancellor',
  'controversy',
  'legendary',
  'sympathy',
  'avoiding',
  'beautifully',
  'antibodies',
  'examinations',
  'surgeons',
  'immunology',
  'departmental',
  'terminology',
  'gentleman',
  'reproduce',
  'convicted',
  'threatening',
  'spokesman',
  'activists',
  'halifax',
  'encourages',
  'assembled',
  'donated',
  'restructuring',
  'terminals',
  'morrison',
  'simulations',
  'sufficiently',
  'examines',
  'conditional',
  'budapest',
  'conceptual',
  'attacked',
  'liechtenstein',
  'translator',
  'automobiles',
  'continent',
  'longitude',
  'challenged',
  'telecharger',
  'insertion',
  'instrumentation',
  'groundwater',
  'strengthening',
  'cologne',
  'insulation',
  'infringement',
  'subjective',
  'asylum',
  'swaziland',
  'varieties',
  'mediawiki',
  'configurations'
];

export const GlobalContext = createContext<GlobalContextDesc>({
  commonWords: null,
  setCommonWords: () => null,
  showVerbs: false,
  setShowVerbs: () => null,
  showSubjects: false,
  setShowSubjects: () => null,
  activeContent: '',
  setActiveContent: () => null
});

export function GlobalProvider({ children }: { children: ReactNode }) {
  const [commonWords, setCommonWords] = useState<Set<string> | null>(null);
  const [showVerbs, setShowVerbs] = useState<boolean>(false);
  const [showSubjects, setShowSubjects] = useState<boolean>(false);
  const [activeContent, setActiveContent] = useState('');

  const fillCommonWords = () => {
    if (commonWords === null) {
      setCommonWords(new Set(_commonWords));
    }
  };
  useEffect(() => {
    fillCommonWords();
  }, []);

  const contextValue = {
    commonWords,
    setCommonWords,
    showVerbs,
    setShowVerbs,
    showSubjects,
    setShowSubjects,
    activeContent,
    setActiveContent
  };

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}

export const useGlobalState = () => useContext(GlobalContext);
