import React, { useEffect, useState } from 'react';
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore
import Worker from 'worker-loader?name=dist/[name].js!./worker';
import { useGlobalState } from './components/shared/GlobalContext';
import { TextContentContentBlocks } from './EditorWorker';
import { Analytics } from './nlp-models/analytics.model';

const worker = new Worker();

export default function Analyzer(props: {
  content: TextContentContentBlocks | undefined;
  onStart: () => void;
  onCompleted: (data: Analytics) => void;
}) {
  const { content, onCompleted, onStart } = props;
  const { commonWords } = useGlobalState();

  useEffect(() => {
    if (content) {
      onStart();
      worker.postMessage({ commonWords, content });
    }
  }, [content]);

  worker.onmessage = (e: MessageEvent) => {
    onCompleted(e.data);
  };

  return <></>;
}
