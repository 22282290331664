import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import Sidebar, { initStatistic } from '../../Sidebar';
import TopMenu from '../shared/TopMenu';
import { Mode } from '../../nlp-models/mode.enum';
import { Analytics } from '../../nlp-models/analytics.model';
import Editor, { getHtmlContent, getTextContent, TextContentContentBlocks } from '../../EditorWorker';
import './dashboard.scss';
import useInterval from '@use-it/interval';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Structure from './Structure';
import Stats from './Stats';
import { materialTheme } from '../shared/MaterialTheme';
import Analyzer from '../../Analyzer';
import { EditorState } from 'draft-js';
import { useGlobalState } from '../shared/GlobalContext';

export default function Dashboard(props: any) {
  const { activeContent, setActiveContent } = useGlobalState();
  const [statistic, setStatistic] = useState<Analytics>(initStatistic);
  const [activeMode, setActiveMode] = useState<Mode>(Mode.WRITE);
  const prevActiveModeRef = useRef<any>();
  useEffect(() => {
    prevActiveModeRef.current = activeMode;
  });
  const prevActiveMode = prevActiveModeRef.current;
  // const [documentTitle] = useState<string>('Homer');
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(true);
  const [initContent, setInitContent] = useState<string>(activeContent);
  const [newContent, setNewContent] = useState<string | undefined>();
  const [contentToAnalyze, setContentToAnalyze] = useState<TextContentContentBlocks | undefined>();
  const [currentEditorState, setCurrentEditorState] = useState<EditorState>();
  const [showStructure, setShowStructure] = useState<boolean>(false);
  const [contentToSave] = useDebounce(newContent, 500);
  const [loading, setLoading] = useState<boolean>(false);

  const myRef = useRef<any>();

  useInterval(() => {
    if (contentToSave) {
      setActiveContent(contentToSave);
    }
  }, 2000);

  useEffect(() => {
    if (contentToSave !== undefined) {
      setActiveContent(contentToSave);
    }
  }, [contentToSave]);

  useEffect(() => {
    if (activeMode === Mode.STATS) {
      if (currentEditorState) {
        setContentToAnalyze(getTextContent(currentEditorState));
      }
      setInitContent(activeContent);
    }
    if (activeMode !== Mode.STATS) {
      (async () => {
        setInitContent(activeContent);
        if (activeMode === Mode.ANALYZE && prevActiveMode !== Mode.STATS) {
          if (currentEditorState) {
            setContentToAnalyze(getTextContent(currentEditorState));
          }
        }
      })();
    }
  }, [activeMode]);

  // useEffect(() => {
  //   if (documentTitle) {
  //     document.title = documentTitle;
  //   }
  // }, [documentTitle]);

  useEffect(() => {
    (async () => {
      if (activeMode === Mode.ANALYZE) {
        if (currentEditorState) {
          setContentToAnalyze(getTextContent(currentEditorState));
        }
      }
    })();
  }, []);

  return (
    <MuiThemeProvider theme={materialTheme}>
      <ToastContainer closeButton={false} />
      <Analyzer
        content={contentToAnalyze}
        onStart={() => {
          setLoading(true);
        }}
        onCompleted={(analytics: Analytics) => {
          setStatistic(analytics);
          setLoading(false);
        }}
      />

      <div className="hmr-container">
        <div className="hmr-toolbar">
          <TopMenu showDocuments={true} />
        </div>
        <div className="hmr-dashboard-body">
          <div className="hmr-copyr">
            Created by <a href="mailto:waqas@homerwritingapp.com">Waqas Younas</a>
          </div>
          <div className="hmr-editor">
            {/* {((!showStructure && initContent === undefined && !documentHash && activeMode !== Mode.STATS) ||
              ((activeMode === Mode.ANALYZE || activeMode === Mode.WRITE) && initContent === undefined)) && (
              <div className="documents-content-container" style={{ marginTop: 20 }}>
                <div className="documents-table">
                  <div style={{ display: 'table-row' }}>Please wait...</div>
                </div>
              </div>
            )} */}
            {showStructure && currentEditorState && (
              <Structure
                ref={myRef}
                content={getHtmlContent(currentEditorState) || ''}
                onFadeEnd={() => {
                  setShowStructure(false);
                }}
              />
            )}
            {!showStructure && activeMode === Mode.STATS && statistic && (
              <Stats
                analytics={statistic}
                onContentAnalyze={() => {
                  if (currentEditorState && !loading) {
                    setContentToAnalyze(getTextContent(currentEditorState));
                  }
                }}
              />
            )}
            {!showStructure && activeMode !== Mode.STATS && (
              <Editor
                initContent={initContent}
                analytics={statistic}
                onContentInit={(editorState: EditorState) => {
                  setCurrentEditorState(editorState);
                }}
                onContentChange={(editorState: EditorState, processImmediately?: boolean) => {
                  const content = getHtmlContent(editorState);
                  setNewContent(content);
                  setCurrentEditorState(editorState);
                  if (processImmediately === true) {
                    setActiveContent(content);
                  }
                }}
                onContentAnalyze={(editorState: EditorState) => {
                  if (activeMode !== Mode.WRITE) {
                    setContentToAnalyze(getTextContent(editorState));
                  }
                }}
                activeMode={activeMode}
                title={'...'}
                id={1}
                documentHash={'empty'}
                saveFlag={false}
              />
            )}
          </div>
          {sideBarVisible ? (
            <div className="hmr-sidebar">
              <div className="sidebar-container">
                <div className="collapse-sidebar">
                  <a
                    onClick={() => {
                      setSideBarVisible(!sideBarVisible);
                    }}
                  >
                    <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                  </a>
                </div>
                <Sidebar
                  onShowStructureSwitch={(show: boolean) => {
                    if (show) {
                      if (currentEditorState) {
                        setActiveContent(getHtmlContent(currentEditorState));
                      }
                      setShowStructure(show);
                    } else {
                      if (myRef.current) {
                        myRef.current.goFade();
                      }
                    }
                  }}
                  switchMode={setActiveMode}
                  activeMode={activeMode}
                  statistic={statistic}
                />
              </div>
            </div>
          ) : (
            <div className="sidebar-collapsed-container">
              <div className="collapse-sidebar">
                <a
                  onClick={() => {
                    setSideBarVisible(!sideBarVisible);
                  }}
                >
                  <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="hmr-copyr-mob">
            Created by <a href="mailto:waqas@homerwritingapp.com">Waqas Younas</a>
        </div>
      </div>
      {loading && (
        <div className="progress-text-check">
          <p>Analyzing results...</p>
          <div className="ball-loader">
            <div className="ball-loader-ball ball2"></div>
            <div className="ball-loader-ball ball3"></div>
            <div className="ball-loader-ball ball1"></div>
          </div>
        </div>
      )}
    </MuiThemeProvider>
  );
}
